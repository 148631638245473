<template>
  <div class="product-item" @click="onClickItem(item)">
<!--    <a  data-fancybox="gallery" :href="img" :data-caption="title">-->
    <div class="product-item-img" :class="video?'product-item-video':''" @click="showPic">
<!--      <van-icon class="item-video" name="play-circle-o" size="40" color="#ffffff" v-if="video" />-->
      <van-image
          class="item-img"
          width="100%"
          lazy-load
          fit="contain"
          :src="img+ '?imageView2/0/w/400/format/jpg'"
      />
    </div>
<!--    </a>-->
    <h5 class="product-item-title text-align-center">{{title}}</h5>
    <div style="display: flex; padding: 0 5px; align-items: center;">
<!--      <p class="product-item-desc " @click="onlineDP" style="color: #000; flex: 1; display: none;"><span style="padding: 5px; background: #fdd600; border-radius: 3px; font-size: 12px;" :style="{background: theme_color, color: theme_color_txt }">在线搭配</span></p>-->

      <p v-if="showSimp" class="product-item-desc " @click="showSimplePics(item)" style="color: #000;"><span style="padding: 5px; background: #fdd600; border-radius: 3px; font-size: 12px; margin-right: 5px;" :style="{background: theme_color, color: theme_color_txt }">类似图</span></p>
      <p v-else>&nbsp;</p>
      <p class="product-item-desc " @click="showMorePics(item)" style="color: #000; "><span style="padding: 5px; background: #fdd600; border-radius: 3px; font-size: 12px;" :style="{background: theme_color, color: theme_color_txt }" v-if="item.img_url2 || item.img_url3">效果图</span></p>
      <div style="float: right; font-size: 12px; flex: 2; text-align: right;"><van-icon name="eye-o" style="vertical-align: text-top;" /> {{item.view_num}}</div>
    </div>

    <p style="border-top: 1px solid #E6E6E6;" class="product-item-desc text-align-center" v-if="size">{{size ? size : '&nbsp;' }}</p>


  </div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage } from 'vant';
// import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import { Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import axios from "axios";

import { Popup } from 'vant';
Vue.use(VanImage);
Vue.use(Popup);

//:src="img+ '?imageView2/1/w/400'"

export default {
  name: 'ProductItem',
  props: {
    theme_color: String,
    theme_color_bg: String,
    theme_color_txt: String,
    title: String,
    video: String,
    info: String,
    cate: Object,
    size: String,
    img: String,
    series: Array,
    item: Object,
    showSimp: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      list:[],
      imgArr:[],
      // show: false,
    };
  },
  mounted() {

    if( this.video ){
      this.imgArr.push(
          {
            // autoplay: true,
            thumb: this.img + '?imageView2/1/w/120',
            src: this.img + '?imageView2/2/w/2000',
            type: "image",
            caption: '<h3 class="img-title-h3">'+this.title+'</h3>' + this.info,
            // info: this.info
          },
      );
      this.imgArr.push(
          {
            autoplay: true,
            thumb: this.img + '?imageView2/1/w/120',
            src: this.video,
            type: "video",
            // caption: '<h3 class="img-title-h3">'+this.title+'</h3>' + this.info,
            // info: this.info
          },
      );
    }else{
      this.imgArr.push(
          {
            thumb: this.img + '?imageView2/1/w/120',
            src: this.img + '?imageView2/2/w/2000',
            type: "image",
            caption: '<h3 class="img-title-h3">'+this.title+'</h3>' + this.info,
            // info: this.info
          },
      );
    }

  if(this.series){
    this.series.forEach( (item) => {
      this.imgArr.push(
          {
            src: item.img_url.replace('\\', '/'),
            type: "image",
            caption: '<h3 class="img-title-h3">'+item.title+'</h3>' + item.info,
            // info: item.info,
            // id: item.id
          },
      );
    })
  }

    // console.log(this.imgArr);
  },
  methods:{
    onClickItem(item){
      item.view_num++;
      console.log(item);
      axios.get(this.apiPrefix + 'api/upView', {
        params: {
          'id': item.id,
        }
      })
          .then(function (response) {
            console.log(response);
          });
    },
    showPic(){
      // let _that = this;
      // const fancybox =
          Fancybox.show( this.imgArr , {
            loop: false,
            // Carousel: {
            //   infinite: false
            // },
            Toolbar: {
              display: [
                "counter",
                // "zoom",
                // "slideshow",
                "fullscreen",
                // "download",
                "thumbs",
                "close",
              ],
            },
          } );
    },
    onlineDP(){
      let _that = this;
      console.log('打开', this.item);
      window.eventBus.$emit('onlineDP', {
        'open':1,
        // 'img':_that.img+ '?imageView2/0/w/800/format/jpg',
        'img':_that.series.length>0?_that.series[0]['img_url']:_that.img,
        'item': _that.item,
        'title': _that.item.title,
      });
    },
    showMorePics(item){

      let _arr = [];

      if(item.img_url2){
        var _imgArr2 = item.img_url2.split(',');
        _imgArr2.forEach(function (val){
          _arr.push(
              {
                autoplay: true,
                thumb: val.replace('\\', '/') + '?imageView2/1/w/120',
                src: val.replace('\\', '/') + '?imageView2/2/w/2000',
                // type: "video",
                caption: '效果图',
                // info: 'this.info'
              },
          );
        })
      }

    if(item.img_url3){
      var _imgArr3 = item.img_url3.split(',');
      _imgArr3.forEach(function (val){
        _arr.push(
            {
              autoplay: true,
              thumb: val.replace('\\', '/') + '?imageView2/1/w/120',
              src: val.replace('\\', '/') + '?imageView2/2/w/2000',
              // type: "video",
              caption: '细节图',
              // info: 'this.info'
            },
        );
      });
      // _arr.push(
      //     {
      //       autoplay: true,
      //       thumb: item.img_url3.replace('\\', '/') + '?imageView2/1/w/120',
      //       src: item.img_url3.replace('\\', '/') + '?imageView2/2/w/1000',
      //       // type: "video",
      //       caption: '细节图',
      //       // info: 'this.info'
      //     },
      // );
    }



      Fancybox.show( _arr , {
        loop: false,
        // Carousel: {
        //   infinite: false
        // },
        Toolbar: {
          display: [
            "counter",
            // "zoom",
            // "slideshow",
            "fullscreen",
            // "download",
            "thumbs",
            "close",
          ],
        },
      } );

    },
    showSimplePics(item){
      // console.log(item.img_url);
      // this.show = true;
      window.eventBus.$emit('loadSimplePics', {
        data:item.img_url, is_url:1, cur_id: item.id
      });
      // this.getList({data:item.img_url, is_url:1});
    },

  }
}
</script>

<style>
.carousel__button.is-next,.carousel__button.is-prev{
  display: none;
}
.img-title-h3{
  text-align: center;
}
</style>
<style>
.item-img{
  vertical-align: middle;
  height: 150px !important;
}

.product-item .van-image{
  margin: 0;
}
.product-item{
  background: #f6f6f6;
  padding: 0;
  /*box-shadow: 0 0 6px #CCC;*/
  box-sizing: border-box;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.product-item-video{
  position: relative;
  /*height: 180px;*/
}
.product-item-video:after{
  /*left: 0;*/
  /*top: 0;*/
  /*content: '';*/
  /*position: absolute;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*background: rgba(0,0,0,0.3);*/
}
.item-video{
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.7;
}
.van-image{
  height: 100%;
  overflow: hidden;
}
.product-item-title,.product-item-desc{
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.product-item-title{
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E6;
}
.product-item-desc{
  padding: 10px 0;
  color: #666;
}
</style>
