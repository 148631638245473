<template>
  <div class="product" style="padding: 0 10px;">
    <div class="search "  style="padding-top: 13px;">
      <div class="group">
        <van-button square type="default" size="small" :color="parseInt(curCate)===item.id?(theme_color?theme_color:'#fdd600'):''" v-for="item in list" v-bind:key="item.id" @click="cateSelect(item)"><span :style="{color: parseInt(curCate)===item.id?theme_color_txt:'' }">{{item.title}}</span></van-button>
      </div>
    </div>

    <div class="content-warp">
      <div v-html="content">
      </div>
    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';
import { Icon } from 'vant';

Vue.use(Icon);

Vue.use(Lazyload);

import { Cell, CellGroup } from 'vant';
import axios from "axios";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'Gongyi',
  components: {
    // vueWaterfallEasy
  },
  data() {
    return {
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
      list:[],
      // imgsArr: [],
      group: 0,// request param
      curCate:0,
      content:''
    };
  },
  created() {
    let that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });
  },
  mounted() {
    let that = this;
    console.log(that.$route.query,'路由参数');
    that.getList(that.$route.query);
  },
  beforeCreate() {


    // let that = this;
    // window.eventBus.$on('refreshPics', function () {
    //   that.getList(that.$route.query);
    // });



  },
  methods:{
    cateSelect(item){
      this.curCate = item.id;
      this.content = item.content;
    },
    getList(params={}){
      let that = this;
      axios.get(this.apiPrefix + 'api/gongyi', {
        params: params
      })
          .then(function (response) {
            that.list = response.data.data;
            if(that.list.length>0){
              that.cateSelect(that.list[0]);
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>
<style>
.content-warp img{
  max-width: 100%;
}
.content-warp{
  line-height: 2em;
}
</style>
