<template>
  <div class="product-item">
    <a  data-fancybox="gallery" :href="img" :data-caption="title">
    <div class="product-item-img">
      <van-image
          v-if="is_video===0"
          width="100%"
          lazy-load
          fit="contain"
          :src="img+ '?imageView2/0/w/400/format/jpg'"
      />
      <div style="text-align: center; position: relative;" v-if="is_video===1">
        <van-icon name="play-circle-o" color="#FFF" style="
        font-size: 72px;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        margin: -36px;" />
        <div style="width: 100%; height: 100%; position: absolute; z-index: 98; background: rgba(0,0,0,0.5)">
        </div>
        <van-image
            v-if="thumb"
            width="100%"
            lazy-load
            fit="contain"
            :src="thumb+ '?imageView2/0/w/400/format/jpg'"
        />
      </div>

    </div>
    </a>
    <h5 class="product-item-title text-align-center">{{title}}</h5>
  </div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage } from 'vant';

Vue.use(VanImage);

//:src="img+ '?imageView2/1/w/400'"

export default {
  name: 'CaseItem',
  props: {
    title: String,
    cate: Object,
    size: String,
    is_video: Number,
    img: String,
    thumb: String,
  },
  data() {
    return {
    };
  },
}
</script>

<style scoped>
.product-item .van-image{
  margin: 0;
}
.product-item{
  background: #FFF;
  padding: 0;
  box-shadow: 0 0 6px #CCC;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.product-item-img{
  /*height: 180px;*/
}
.van-image{
  height: 100%;
  overflow: hidden;
  min-height: 100px;
}
.product-item-title,.product-item-desc{
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.product-item-title{
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E6;
}
.product-item-desc{
  padding: 10px 0;
  color: #666;
}
</style>
