<template>
  <div class="online-dp" v-if="visible">
    <van-button class="close-btn" icon="cross" type="primary" @click="closeDp" style="background: transparent; border: none;" />






    <div id="imgEffectEditHolder" style="width: 100%;  height: 100%;" v-show="showContent">

      <div id="imgEffectEditWarp" style="width: 100%; height: 100%; background-position: 10px 20px;">

        <div id="imgBg" style=" width: 100%; height: 400px; position: relative; " v-show="showImg == 1">
          <div id="multEffectEditImgs" style=" top: 0; left: 0; z-index: 10; width: 100%; height: 100%;  background-repeat: no-repeat,no-repeat;" :style="{backgroundImage:'url(' + pic + '?imageView2/0/w/1000),url(' + img.path + ')',backgroundSize: 'cover,' + r_width + 'px', backgroundPosition: '0 0,' +  r_x + 'px ' + r_y + 'px'}"></div>

        </div>

      </div>



    </div>





    <div class="panel-comm scene" v-show="sceneVisible">

      <van-row>
        <van-col span="16"><van-icon name="orders-o" /> 换场景 <span class="font-size-12">左右滑动查看更多</span></van-col>
        <van-col span="8" style="text-align: right;"><van-button size="small" class="close-btn" icon="cross" @click="closeScene" style="background: transparent; color: #fff; border: none;" /></van-col>
      </van-row>

      <div class="thumb-list" style="padding-bottom: 5px;">
        <ul class="nav_list_holder">


          <li :class="curCJSelectID===0?'cur':''" data-id="0" @click="selectCJCate(0)" >全部场景</li>
          <li :class="curCJSelectID===item2.id?'cur':''" :data-cid="item2.id" class="" v-bind:key="item2.id" v-for="(item2) in cjlist"  @click="selectCJCate(item2.id)">{{item2.title}}</li>



        </ul>
      </div>

      <div class="thumb-list" style="padding-bottom: 30px;">
        <ul class="changjing__imgs scroll__style">


          <li :data-cid="item.id" class="" v-bind:key="item.id" v-for="item in list2">
            <van-image
                @click="selectPic(item.id, item.img_url.replace('\\','/'))"
                :src="item.img_url.replace('\\','/') + '?imageView2/0/h/120'"
                width="100px"
                height="100px"
                lazy-load
                fit="cover"
            />
          </li>



        </ul>
      </div>

    </div>

    <div class="panel-comm product" v-show="productVisible">

      <van-row style="display: none;">
        <van-col span="16"><van-icon name="arrow" /> 调整图片</van-col>
        <van-col span="8" style="text-align: right;"><van-button size="small" class="close-btn" icon="cross" @click="closeProduct" style="background: transparent; color: #fff; border: none;" /></van-col>
      </van-row>

      <div class="gai-btns" style="display: none;">
        <button class="btn mdui-ripple gai-img" data-type="1" @click="changeSize(1)">放大</button>
        <button class="btn mdui-ripple gai-img" data-type="2" @click="changeSize(2)">缩小</button>
        <button class="btn mdui-ripple gai-img" data-type="3" @click="changePos(1)">上</button>
        <button class="btn mdui-ripple gai-img" data-type="4" @click="changePos(2)">下</button>
        <button class="btn mdui-ripple gai-img" data-type="5" @click="changePos(3)">左</button>
        <button class="btn mdui-ripple gai-img" data-type="6" @click="changePos(4)">右</button>
        <button class="btn mdui-ripple gai-img" data-type="7" @click="reSize()">重设</button>
      </div>

      <van-row>
        <van-col span="16"><van-icon name="orders-o" /> 换产品 <span class="font-size-12">左右滑动查看更多分类</span></van-col>
        <van-col span="8" style="text-align: right;"><van-button size="small" class="close-btn" icon="cross" @click="closeProduct" style="background: transparent; color: #fff; border: none;" /></van-col>
      </van-row>

      <div class="thumb-list" style="padding-bottom: 5px; ">
        <ul class="nav_list_holder">


          <li :class="curProCateSelectID===0?'cur':''" data-id="0" @click="selectProCate(0)">全部产品</li>
          <li :class="curProCateSelectID===item2.id?'cur':''" :data-cid="item2.id" class="" v-bind:key="item2.id" v-for="(item2) in proCate"  @click="selectProCate(item2.id)">{{item2.title}}</li>



        </ul>
      </div>

      <div class="product-wrapper2">

          <van-list
              class="feed-list"
              v-model="loading"
              :finished="finished"
              offset="100"
              finished-text="没有更多了"
              @load="onLoad(false)"
          >

              <div :data-cid="item.id" :data-title="item.title" class="product-item" v-bind:key="index" v-for="(item,index) in proList2">
                <van-image
                    class="v-img"
                    @click="selectProPic(item)"
                    :src="item.img_url.replace('\\','/') + '?imageView2/0/h/120'"
                    width="100px"
                    height="100px"
                    lazy-load
                    fit="cover"
                />
              </div>
          </van-list>

      </div>

    </div>

    <div class="btns">

      <div class="img-series">
        <ul class="changjing__imgs scroll__style" style="align-items: center; justify-content: center;">
          <li v-if="item.series && item.series.length===0">
            <van-image
                :class="curSelectIndex ===0?'cur':''"
                @click="selectPic2(0, img2.path.replace('\\','/'))"
                :src="img2.path.replace('\\','/') + '?imageView2/0/h/80'"
                width="45px"
                height="45px"
                lazy-load
                fit="cover"
            />
            <div style="font-size: 12px;">{{img2.title}}</div>
          </li>
          <li :data-cid="item2.id" class="" v-bind:key="item2.id" v-for="(item2, index) in item.series" v-else>
            <van-image
                :class="index===curSelectIndex?'cur':''"
                @click="selectPic2(index, item2.img_url.replace('\\','/'))"
                :src="item2.img_url.replace('\\','/') + '?imageView2/0/h/80'"
                width="45px"
                height="45px"
                lazy-load
                fit="cover"
            />
            <div style="font-size: 12px;">{{item2.title}}</div>
          </li>
        </ul>
      </div>

      <van-button type="default" round @click="showScene" plain :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">换场景</span></van-button>
<!--      <van-button type="default" round @click="showProduct" plain :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">换产品</span></van-button>-->
      <van-button type="default" round @click="showCropPop" plain :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">裁切壁画</span></van-button>
<!--      <van-button type="default" round @click="showPopup" plain :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">花型寓意</span></van-button>-->
      <van-button type="default" round plain :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">相似图</span></van-button>
      <div style="clear: both; margin-top: 20px;">
        <van-button style="width: 94%; margin: 0 auto; background: #fed700; color: #000 !important; border: none;" type="default" round block @click="screen()" :style="{background:(theme_color?theme_color:'#fdd600'), color: theme_color_txt}">生成效果图保存</van-button>
      </div>
    </div>


    <van-popup class="my-con" closeable v-model="show" position="top" :style="{ width: '100%',height: '50%' , padding: '20px 0'}">

      <div class="p-con">
        <h2 style="color: #fff;">型号：{{item.title}}</h2>
        <h3>花型寓意</h3>
        {{item.keywords}}
      </div>


    </van-popup>


    <div class="crop-container" v-show="showCrop">
      <div style="margin-top: 20px; position: relative;">

        <van-button size="small" class="close-btn" icon="cross" @click="closeCrop" style="background: transparent; color: #fff; border: none; position: absolute; right: 20px; top: 20px;" />

        <vue-croppie
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="true"
            :showZoomer="false"
            :boundary="{ width: '90%', height: 420}"
            :viewport="{ width: this.refreshCropperConfig.width, height: this.refreshCropperConfig.height}"
            @result="result"
            @update="update"
        >
        </vue-croppie>

        <div style="padding: 0 20px;">
          <van-row gutter="20">
            <van-col span="12">
              <van-field
                  type="number"
                  v-model="cropperConfig.width"
                  label="宽度(CM)"
                  placeholder="宽度"
                  @input="onWhRefresh($event,'w')"
                  @focus="onFocus"
                  @blur="onBlur"
              />
            </van-col>
            <van-col span="12">
              <van-field
                  type="number"
                  v-model="cropperConfig.height"
                  label="高度(CM)"
                  placeholder="高度"
                  @input="onWhRefresh($event,'h')"
                  @focus="onFocus"
                  @blur="onBlur"
              />
            </van-col>
          </van-row>

<!--          <div style="margin: 20px 0;">-->
<!--            <van-button round block type="default" native-type="button" @click="refresh()">设定尺寸</van-button>-->
<!--          </div>-->


          <div style="margin: 40px 0;">
            <van-button round block type="primary" native-type="button" @click="crop()">裁切</van-button>
          </div>

        </div>

      </div>

<!--      <img v-bind:src="cropped">-->
    </div>


    <van-popup class="my-con" v-model="showScreen" :style="{ width: '100%',height: '100%' }">

      <div class="img-con">
        <img :src="screenData" v-if="screenData">
      </div>

      <div class="img-btn">
        长按图片可保存到相册
        <div style="margin-top: 10px;"><van-button type="primary" @click="closeScreen()">关闭</van-button></div>
      </div>


    </van-popup>

  </div>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery'
import Hammer from 'hammerjs'
import { Toast } from 'vant';
import axios from "axios";
import { Image as VanImage } from 'vant';
import { Popup } from 'vant';
import html2canvas from 'html2canvas';


import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually


Vue.use(VueCroppie);

Vue.use(VanImage);
Vue.use(Popup);

Vue.use(Toast);

//这个不错：https://github.com/xyxiao001/vue-cropper


let app2 = {
  name: 'OnlineDP',
  props: {
    // msg: String
  },
  data() {
    return {
      screenData: '',
      cropperConfig: {width: 200, height: 300},
      refreshCropperConfig: {width: 200, height: 300},
      showScreen: false,
      showCrop: false,
      cropped: null,
      show: false,
      visible:false,
      sceneVisible:false,
      productVisible:false,
      // isInit:false,
      images: [
      ],
      showLoading: 0,
      showImg:1,
      img:{
        'id' :0,
        'path' :'',
        'title' :'',
        'width':0,
        'height':0
      },
      img2:{
        'id' :0,
        'path' :'',
        'title' :'',
        'width':0,
        'height':0
      },
      r_width:0,
      r_height:0,
      r_x:0,
      r_y:0,
      effect_id:0,
      pic:'',
      showContent:false,
      list:[],
      list2:[],
      cjlist:[],
      item: {},
      curSelectIndex: 0,
      curSelectImg: '',
      curCJSelectID: 0,
      proList:[],
      proList2:[],
      proCate:[],
      curProCateSelectID: 0,
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
      beginImgUrl: '',
      loading: false,
      finished: false,
      page: 1,
    };
  },
  created() {
    let that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });
  },
  mounted() {
    let _that = this;



    console.log('初始化在线搭配');
    // if(_that.isInit) return;
    // _that.isInit = true;
    window.eventBus.$off('onlineDP');
    window.eventBus.$on('onlineDP', function (data) {
      console.log(data);
      _that.item = data.item
      _that.visible = true;
      document.getElementsByTagName("body")[0].setAttribute("style","overflow: hidden;");

      _that.img.path = data.img.replace('\\','/');
      _that.img.title = data.title;
      _that.img2.path = data.img.replace('\\','/');
      _that.img2.title = data.title;
      _that.curSelectImg = data.img.replace('\\','/');
      _that.initDp();

    });

    window.eventBus.$off('gotoOnlineDP');
    window.eventBus.$on('gotoOnlineDP', function (data) {
      console.log(data);
      _that.selectProPic(_that.proList[0]);
    });

    this.getList({'uid':this.uid});
    this.getCjList({'uid':this.uid});
    // this.getProCate({'uid': this.uid});
    // this.getProList({
    //   uid: this.uid
    // });



  },
  methods:{
    onFocus(){

      const ua = navigator.userAgent;
      const iOS = /iPad|iPhone|iPod/.test(ua);

          if (iOS) {
            if (!/OS 11_[0-3]\D/.test(ua)) {
              // document.body.scrollTop = document.body.scrollHeight;
            }
          } else {
            // e.target.scrollIntoView(false);
            $('.crop-container').addClass('up-crop');
          }
    },
    onBlur(){
      const ua = navigator.userAgent;
      const iOS = /iPad|iPhone|iPod/.test(ua);

      if (iOS) {
        if (!/OS 11_[0-3]\D/.test(ua)) {
          // document.body.scrollTop = document.body.scrollHeight;
        }
      } else {
        // e.target.scrollIntoView(false);
        $('.crop-container').removeClass('up-crop');
      }
    },

    onLoad(params) {

      console.log(params);

      var that = this;

      // if(!params){
        var _obj = {uid: this.uid, cate: this.curProCateSelectID};
        _obj.page = that.page;
        that.queryObj = _obj;
      // }

      console.log(that.queryObj, '参数啊');

      axios.get(that.apiPrefix + 'api/pics', {
        params: that.queryObj
      })
          .then(function (response) {
            that.loading = false;

            //处理数据：有系列图的，把系列图加入，如果没有的，就用效果图的缩略图
            var _arr = [];
            response.data.data.data.forEach(function(item) {
              if( item.series.length > 0 ){
                //可能有多个单元图数据，把img_url替换为单元图数
                item.series.forEach(function(item2) {
                  console.log(item2, item.id);
                  var _newItem = {...item};
                  // var _newItem = Object.assign( {},  item);
                  _newItem['img_url'] = item2['img_url'];
                  _newItem['title'] = item2['title'];
                  //检测是否存在重复的
                  // if(_newItem['title']){
                    _arr.push(_newItem);
                  // }

                });
                console.log('---------------------2');
              }else{
                //否则采用之前的
                _arr.push(item);
                console.log('---------------------1');
              }
            });
            // if( series ){
            //
            // }


            // that.list.concat(response.data.data.data);
            // that.proList2.push(...response.data.data.data);
            // that.proList2.push(..._nArr);
            that.proList2.push(..._arr);

            //去重
            var _nArr = that.proList2.filter((currentValue, currentIndex, selfArr) =>{
              return selfArr.findIndex(x => x.title === currentValue.title) === currentIndex
            })

            that.proList2 = _nArr;

            // console.log( that.proList2 );

            if( response.data.data.last_page === response.data.data.current_page || response.data.data.last_page === 0 ) {
              that.finished = true;
            }else{
              that.page+=1;
              that.finished = false;
            }

            console.log(that.theme_color, '皮肤颜色');

          })
          .catch(function (error) {
            console.log(error);
          });

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //
      //   // 加载状态结束
      //   this.loading = false;
      //
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    result(output, e,e2,e3) {
      console.log(output, e,e2,e3);
      // this.cropped = output;
    },
    onWhRefresh($event, e){
      console.log($event, e);
      this.refresh();
    },
    inputSize(e){
      console.log(e);
      // this.$nextTick(function (){
      //   this.cropperConfig.width = 300
      //   this.cropperConfig.height = 100
      // })
      // this.$refs.croppieRef.
    },
    showPopup() {
      this.show = true;
    },
    closeDp(){
      let _that = this;
      _that.visible = false;
      document.getElementsByTagName("body")[0].setAttribute("style","overflow: auto;");
    },
    showScene(){
      let _that = this;
      _that.sceneVisible = true;
    },
    closeScene(){
      let _that = this;
      _that.sceneVisible = false;
    },
    showProduct(){
      let _that = this;
      _that.productVisible = true;
      if( _that.proList2.length === 0 ){
        //没有数据
        _that.onLoad({});
      }
    },
    showProduct2(){
      alert('开发中')
    },
    closeProduct(){
      let _that = this;
      _that.productVisible = false;
    },
    changeSize:function (isAdd) {
      if(isAdd == 1){
        this.r_width += 15;
      }else{
        this.r_width -= 15;
      }
    },
    changePos:function (type) {
      switch (type) {
        case 1:
          this.r_y -= 5;
          break;
        case 2:
          this.r_y += 5;
          break;
        case 3:
          this.r_x -= 5;
          break;
        case 4:
          this.r_x += 5;
          break;
      }
    },
    reSize:function () {
      this.r_width = this.img.width;
      this.r_x = 0;
      this.r_y = 0;
    },
    selectPic:function(id,path){
      this.pic = path;
      this.effect_id = id;


      var _that = this;
      var img = new Image();
      img.src = this.pic;
      img.onload = function(){
        console.log('width:'+img.width+',height:'+img.height, 'ddffdfdf')
        _that.showLoading = 0;
        _that.showImg = 1;
        // app2.img.width = img.width;//改为屏幕宽度了
        _that.img.width = $(window).width();//改为屏幕宽度了
        _that.img.height = img.height;
        _that.r_width = $(window).width();//使用屏幕的宽度
        _that.r_height = img.height;

        var _height = ($(window).width() * img.height )/img.width;
        $("#imgBg").height(Math.round(_height));
        // $("#imgBg").css({'margin-top':($(window).height() - _height)/2 - 50});
        // $("#imgBg").css({'margin-top':50});

        // setTimeout(function (){
        // _that.showContent = true;
        // Toast.clear();
        // _that.initHammer();
        // }, 500);


        return {width:img.width, height:img.height};
      }

    },
    selectPic2:function(index, path){
      //'?imageView2/0/w/800/format/jpg'
      this.curSelectIndex = index;
      this.curSelectImg = path;
      this.img.path = path.replace('\\','/');

      this.beginImgUrl = path + '?imageView2/0/w/1000/format/jpg';
      // this.beginImgUrl = path;
      // console.log(this.curSelectImg);

    },
    initDp:function (){
      this.showLoading = 1;

      Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });


      // this.img.path;

      // console.log( this.pic,this.img.path );

      this.beginImgUrl = this.img.path;

      this.getBGImgWH2(this.pic,this.img.path);
    },
    getBGImgWH2: function(img_url, imgPath){
      var _that = this;
      var img = new Image();
      img.src = img_url;
      img.onload = function(){
        // console.log( img.width, img.height );
        // console.log('width:'+img.width+',height:'+img.height)
        // app2.showLoading = 0;
        // app2.showImg = 1;
        // app2.img.width = img.width;
        // app2.img.height = img.height;
        // app2.r_width = img.width;
        // app2.r_height = img.height;

        var _height = ($(window).width() * img.height )/img.width;
        $("#imgBg").height(Math.round(_height));
        // $("#imgBg").css({'margin-top':($(window).height() - _height)/2 - 20});
        // $("#imgBg").css({'margin-top':50});

        _that.getImgWH2(imgPath);

        return {width:img.width, height:img.height};
      }
    },
    getImgWH2:function (img_url){
      var _that = this;
      var img = new Image();
      img.src = img_url;
      img.onload = function(){
        // console.log('width:'+img.width+',height:'+img.height)
        _that.showLoading = 0;
        _that.showImg = 1;
        // app2.img.width = img.width;//改为屏幕宽度了
        _that.img.width = $(window).width();//改为屏幕宽度了
        _that.img.height = img.height;
        _that.r_width = $(window).width();//使用屏幕的宽度
        _that.r_height = img.height;

        // setTimeout(function (){
          _that.showContent = true;
          Toast.clear();
        _that.initHammer();
        // }, 500);


        return {width:img.width, height:img.height};
      }
    },
    initHammer:function (){
      let _that = this;

      var myElement = document.getElementById('imgEffectEditHolder');

      var mc = new Hammer(myElement);
      mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      // var _multEffectEditImgs = $("#multEffectEditImgs");
      var _lastX = 0, _lastY = 0;
      mc.on("panleft panright panup pandown tap press", function(ev) {
        // console.log( ev.type +" gesture detected.", ev.deltaX, ev.deltaY );
        _that.r_x = ev.deltaX + _lastX;
        _that.r_y = ev.deltaY + _lastY;
      });
      mc.on("panend", function(ev) {
        console.log( ev.type +" gesture detecdddddddted.", ev.deltaX, ev.deltaY );
        _lastX = _that.r_x;
        _lastY = _that.r_y;
      });



      var pinch = new Hammer.Pinch();
      mc.add([pinch]);

      mc.get('pinch').set({ enable: true });

      var _lastScale = $(window).width();//使用屏幕的宽度
      mc.on("pinchstart", function(ev) {
        // alert('dd');
        console.log(ev);
      });
      mc.on("pinchend", function(ev) {
        console.log(ev);
        // alert('dd');
        // _lastScale = app2.r_width;
        // alert(_lastScale);
        _lastScale =  _that.r_width;
      });
      mc.on("pinch", function(ev) {
        // alert(ev.scale);
        _that.r_width = ev.scale * _lastScale;
        // $("#sss").text( (ev.scale)* app2.r_width + ',' +  app2.r_width + ',' +  ev.scale + ',' +  _lastScale);
      });

    },
    getList(params={}){
      let that = this;
      axios.get(this.apiPrefix + 'api/xg-pics', {
        params: params
      })
          .then(function (response) {
            that.list = response.data.data.data;
            that.list2 = response.data.data.data;
            if(that.list.length>0){
              that.pic = that.list[0].img_url.replace('\\','/')
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    },

    getCjList(params={}){
      let that = this;
      axios.get(this.apiPrefix + 'api/pic-cate', {
        params: params
      })
          .then(function (response) {
            console.log(response.data.data,'kkkk');
            that.cjlist = response.data.data;
            // if(that.list.length>0){
            //   that.pic = that.list[0].img_url.replace('\\','/')
            // }

          })
          .catch(function (error) {
            console.log(error);
          });
    },

    selectCJCate(id){
      this.curCJSelectID = id;
      if(id===0){
        this.list2 = this.list
      }else{
        this.list2 = this.list.filter(item=>item.category_id==id)
      }

    },

    // getProList(params={}){
    //   let that = this;
    //   axios.get(this.apiPrefix + 'api/pics2', {
    //     params: params
    //   })
    //       .then(function (response) {
    //         that.proList = response.data.data.data;
    //         that.proList2 = response.data.data.data;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // },

    getProCate(params={}){
      let that = this;
      axios.get(this.apiPrefix + 'api/cate', {
        params: params
      })
          .then(function (response) {
            // console.log(response.data.data,'kkkk2');
            that.proCate = response.data.data;
            // if(that.list.length>0){
            //   that.pic = that.list[0].img_url.replace('\\','/')
            // }

          })
          .catch(function (error) {
            console.log(error);
          });
    },

    selectProCate(id){

      var that = this;

      that.curProCateSelectID = id;
      // if(id===0){
      //   this.proList2 = this.proList
      // }else{
      //   this.proList2 = this.proList.filter(item=>item.category_id==id)
      // }

      // let _obj = that.$route.query;
      // if(data.key){
      //   _obj.keyword = data.key;
      // }
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.proList2 = [];
      // // console.log(_obj);
      // that.queryObj = _obj;
      that.onLoad(false);

    },

    selectProPic(item){
      // this.curSelectIndex = index;
      // this.curSelectImg = path;
      // this.img.path = path.replace('\\','/');
      // console.log(this.curSelectImg);
      console.log(item);
      var _that = this;
      _that.item = item
      _that.visible = true;
      document.getElementsByTagName("body")[0].setAttribute("style","overflow: hidden;");

      _that.img.path = item.img_url.replace('\\','/');
      _that.img2.path = item.img_url.replace('\\','/');
      _that.curSelectImg = item.img_url.replace('\\','/');
      _that.initDp();


    },

    // update(val) {
    //   console.log(val);
    // },
    bind() {
      // Randomize cat photos, nothing special here. https://github.com/jofftiquez/vue-croppie  https://foliotek.github.io/Croppie/
      // let url = this.img.path
      if(!this.beginImgUrl) return;
      let url = this.beginImgUrl

      // Just like what we did with .bind({...}) on
      // the mounted() function above.
      this.$refs.croppieRef.bind({
        url: url,
      });
    },
    showCropPop(){
      this.showCrop = true;
      this.bind();
    },
    closeCrop(){
      this.showCrop = false;
    },
    refresh(){

      //转换数据

      //计算倍数
      // var _percent = 1;
      // var _oldWidth = this.cropperConfig.width;
      // var _oldHeight = this.cropperConfig.height;
      // var _newWidth = 0;
      // var _newHeight = 0;
      // if( _oldWidth >= _oldHeight ){
      //   _percent = $(window).width()/_oldWidth;
      //   _newWidth = $(window).width();
      //   _newHeight = _oldHeight * _percent;
      // }else{
      //   _percent = $(window).height()/_oldHeight;
      //   _newHeight = $(window).height();
      //   _newWidth = _oldWidth * _percent;
      // }

      this.refreshCropperConfig.width = this.cropperConfig.width;
      this.refreshCropperConfig.height = this.cropperConfig.height;

      this.$nextTick(function (){
        this.$refs.croppieRef.refresh();
        this.bind();
      })
    },
    crop() {


      console.log(this.$refs.croppieRef);


      // this.$nextTick(function (){
      //   this.cropperConfig.width = 300
      //   this.cropperConfig.height = 100
      // })

      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        format: 'jpeg',
        size: 'original',
        // size: {width: 1000, height:1000},
        circle: false
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output;
        console.log(this.cropped);
        this.showCrop = false;
        this.img.path = this.cropped;
      });
    },
    update(val) {
      console.log(val);
    },

    screen(){

      // var _imgBG = $("#imgBg");
      // var _width = _imgBG.width();
      // var _height = _imgBG.height();
      // console.log(_width,_height);
      // _imgBG.width(_width * window.devicePixelRatio)
      // _imgBG.height(_height * window.devicePixelRatio)

      var _that = this;
      html2canvas(document.querySelector("#imgBg"),{
        // width:_width,
        // height:_height,
        allowTaint: true,
        useCORS: true,
        // scale: window.devicePixelRatio || 1,
        // dpi: 300, // 处理模糊问题
      }).then(function(canvas) {
        _that.screenData = canvas.toDataURL('image/png');
        _that.showScreen = true;
      });

      // console.log($("#imgBg").width(), $("#imgBg").height());




    },
    closeScreen(){
      this.showScreen = false;
    }

  }
}




export default app2;

// console.log(app2.data().img, 'dddd');

</script>

<style scoped>
.img-con{
  position: relative;
}
.img-btn{
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  position: absolute;
  bottom: 30px;

}
.van-toast{
  z-index: 99999!important;
}
#imgBg{
  background: #fff;
}
.online-dp{
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000;
  z-index: 999;
  color: #ccc;
  top: 0;
}
.close-btn{
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.9;
  z-index: 99;
}
.btns{
  padding: 10px 0;
  position: absolute;
  bottom: 20px;
  width: 100%;
  box-sizing: content-box;
  text-align: center;
}
.btns .van-button{
  width: 45%;
  margin: 2%;
}
.btns .van-button--default {
  color: #fed700;
  background-color: #222;
  border: 1px solid #fed700;
}
.panel-comm{
  border-top: solid 1px #333;
  overflow: hidden;
  background: rgba(0, 0, 0, 1);
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  z-index: 999998;
  padding: 20px 10px 10px 10px;
  min-height: 140px;
}
.font-size-12{
  font-size: 12px;
  color: #777;
}
.changjing__imgs {
  height: 120px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
}
.changjing__imgs li {
  float: left;
  margin: 5px 5px;
  max-width: 100px;
}
.changjing__imgs li img {
  height: 100px;
}
.thumb-list{
  padding-top: 20px;
}
.gai-btns {
  text-align: center;
  padding: 20px 5px;
}
.gai-btns button {
  padding: 10px 12px;
  border: solid 1px #CCC;
  background: transparent;
  border-radius: 20px;
  color: #CCC;
  font-size: 14px;
  margin: 0 3px;
  outline: none;
}
.mdui-ripple {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.van-image{
  background: #fff;
  margin: 0;
}
.img-series{
  width: 100%;
  height: 50px;
  position: absolute;
  top: -70px;
  /*background: #E6E6E6;*/
}
.img-series .changjing__imgs{
  height: 65px;
}
.img-series .van-image{
  border: solid 3px #000;
}
.img-series .cur{
  border: solid 3px #fed700;
}
.p-con{
  padding: 20px;
  color: #fed700;
  line-height: 1.8em;
}

.my-con{
  background: rgba(0,0,0,0.85);
  border-top: solid 20px transparent;
  border-bottom: solid 20px transparent;
  width: auto;
}

.nav_list_holder {
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  content-visibility: auto;
}
.nav_list_holder li {
  color: #fed700;
  line-height: 27px;
  font-size: 14px;
  text-align: center;
  flex: 0 0 90px;
  /*float: left;*/
  margin: 5px 5px;
  /*max-width: 100px;*/
  border: solid 1px #caba63;
  border-radius: 30px;
}
.nav_list_holder li.cur {
  background: #fed700;
  color: #000;
}

.crop-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 109;
}
.img-con img{
  max-width: 90%;
}
.img-con{
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
</style>

<style type="less">
.van-list__finished-text,.van-list__loading{
  //position: absolute;
  //left: 0;
  //right: 0;
  //width: 100%;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}
</style>
<style type="less" scoped>

.feed-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //column-count: 2;
  //column-gap: 13px;
  padding: 0;
  overflow: auto;
  height: 300px;
}

.v-img{
  margin: 0 auto;
}

.product-item{
  box-sizing: border-box;
  flex: 0 0 33.33%;
  padding: 7px 5px;
  text-align: center;
}
.product-item:nth-child(2n-1){
  //border-right: 4px #fff solid;
}
.product-item:nth-child(2n){
  //border-left: 4px #fff solid;
}

.up-crop{
  transform: translateY(-70px);
  height: 130%;
}

</style>
