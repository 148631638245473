<template>
  <div>
    <div class="search "  style="padding-top: 0px;" v-if="cate.length>0">

      <div class="group">
        <van-button square type="default" size="small" :color="theme_color?theme_color:'#fdd600'" @click="cateSelect(0)"><span :style="{color: theme_color_txt}">标签</span></van-button>
        <van-button square type="default" size="small" color="#999999" @click="cateSelect(0)">不选</van-button>
        <van-button square type="default" size="small" :color="curCate.indexOf(item.id.toString())!==-1?(theme_color?theme_color:'#fdd600'):''" v-for="item in cate" v-bind:key="item.id" @click="cateSelect(item.id,item)"><span :style="{color: curCate.indexOf(item.id.toString())!==-1?theme_color_txt:''}">{{item.title}}</span></van-button>
      </div>
      <div class="group" style="padding-top: 0px; display: none; ">
        <van-button square type="default" size="small" :color="parseInt(curSubCate)===item.id?(theme_color?theme_color:'#fdd600'):''" v-for="item in subCate" v-bind:key="item.id" @click="subCateSelect(item.id)">{{item.title}}</van-button>
      </div>
      <div class="group" v-for="item in attr" v-bind:key="item.id" style="display: none;">
        <van-button square type="default" size="small" :color="theme_color?theme_color:'#fdd600'" @click="attrSelect(item.id, 0)">{{item.title}}</van-button>
        <van-button square type="default" size="small" color="#999999" @click="attrSelect(item.id, 0)">不选</van-button>
<!--         parseInt(curAttrGroup)===item.id && parseInt(curAttrID)===item2.id-->
        <van-button square type="default" size="small" :color="parseInt(curQuery['attr2_'+item.id])===item2.id?(theme_color?theme_color:'#fdd600'):''" v-for="item2 in item.children" v-bind:key="item2.id" @click="attrSelect(item.id, item2.id)">{{item2.title}}</van-button>
      </div>
    </div>

    <div style="text-align: center; margin-bottom: 20px; display: none;">
      <van-button square type="default" size="small" color="#999999" @click="showMore"><van-icon class="icon" name="arrow-down" /> {{isShowMore?'收起':'查看更多选项'}} </van-button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import { Button } from 'vant';
import { Field } from 'vant';
import { Col, Row } from 'vant';
import axios from "axios";

Vue.use(Col);
Vue.use(Row);

Vue.use(Field);

Vue.use(Button);

export default {
  name: 'Search',
  props: {
    // msg: String
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      theme_color_bg: '',
      theme_color_bg_txt: '',
      theme_color: '',
      theme_color_txt: '',
      keyword:'',
      category: 0, //分类ID
      cate:[],
      attr:[],
      curCate:[],
      curSubCate:0,
      curAttrGroup:0,
      curAttrID:0,
      isShowMore:false,
      curQuery:{},
      subCate:[]
    };
  },
  watch:{
    $route(to,from){
      // console.log(from.query['attr_11'],'qu');
      this.curQuery = to.query;
      // console.log(this.curQuery);
      console.log(from);
    }
  },
  created() {
    let that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    that.theme_color_bg_txt = that.themeColorBgTxt;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
      that.theme_color_bg_txt = that.themeColorBgTxt;
    });
  },
  methods:{
    showMore(){
      console.log('更多');
      this.isShowMore = (!this.isShowMore);
    },
    gotoSearch(){


      window.eventBus.$emit('searchKeyWord', {
        'key':this.keyword
      });

      console.log( this.$route.path === '/product' );
      if(this.$route.path !== '/product'){
        this.$router.push( { path:'/product',
          query:{
            uid: this.uid,
            keyword:this.keyword
          } } )
      }else{
        this.$router.push( { path:'/product',
          query:{
            uid: this.uid,
            keyword:this.keyword
          } } )
      }


    },
    gotoImgSearch(){

      this.$router.push( { path:'/img-search',
        query:{
          uid: this.uid
        }  } )


    },
    cateSelect(id,item){
      // alert(this.category)
      // console.log(id,this.$router.currentRoute.path==='/product', item, 'tttt');
      if(item && item.children){
        this.subCate = item.children;
      }else{
        this.subCate = [];
      }
      // if(this.$router.currentRoute.path==='/product') {
      //   //刷新数据
      //   this.$router.push( { path:'/product',
      //     query:{
      //       cate: id,
      //       uid: this.uid
      //     } } );
      // }else{
      //   this.$router.push( { path:'/product',
      //     query:{
      //       cate: id,
      //       uid: this.uid
      //     } } );
      // }

      this.curCate.forEach(function (value) {
        console.log(value);
      });

      if( parseInt(id) === 0 ){
        this.curCate = [];
      }else{
        //检测 是否存在，不存在，就添加；存在就删除，就是复合型的
        var _index = this.curCate.indexOf(id.toString());
        if(_index !== -1) {
          this.curCate.splice( _index , 1);
        }else{
          this.curCate.push( id.toString() );
        }
      }



      // this.curCate.forEach(function (value) {
      //   if( parseInt(id) === parseInt(value) ){
      //     //存在，就删除
      //     console.log(this.curCate.indexOf(id.toString()));
      //     // this.curCate.splice(this.curCate.indexOf(id.toString()), 1);
      //   }else{
      //     //不存在，添加
      //     this.curCate.push( id.toString() );
      //   }
      //   // console.log(value,index);
      // });


      // let _attrKey = 'attr_' + this.curAttrGroup;
      let _obj = {
        ...this.$route.query,
        cate: this.category,
        sub_cate: 0,
        uid: this.uid,
        attr_g: this.curAttrGroup,
        attr: this.curAttrID,
        tag_id: this.curCate.join(','),
        // _attrKey: this.curAttrID,
      };
      _obj['attr2_' + this.curAttrGroup] = this.curAttrID;

      console.log(_obj);

      this.$router.push( { path:'/product',
        query: _obj} );


      this.curSubCate = 0;
      window.eventBus.$emit('refreshPics', {
      });

      this.getTag();

    },
    subCateSelect(id){
      let _obj = {
        ...this.$route.query,
        cate: this.category,
        sub_cate: 0,
        uid: this.uid,
        attr_g: this.curAttrGroup,
        attr: this.curAttrID,
        tag_id: id,
        // _attrKey: this.curAttrID,
      };
      _obj['attr2_' + this.curAttrGroup] = this.curAttrID;

      console.log(_obj);

      this.$router.push( { path:'/product',
        query: _obj} );

      this.curSubCate = id;
      window.eventBus.$emit('refreshPics', {
      });
    },
    attrSelect(group, id){
      console.log(group, id);

      let _obj = {
        ...this.$route.query,
        attr_g: group,
        attr: id,
        uid: this.uid,
        cate: this.category,
      };

      _obj['attr2_' + group] = id;

      this.$router.push( { path:'/product',query: _obj} );
      this.curAttrGroup = group;
      this.curAttrID = id;

      window.eventBus.$emit('refreshPics', {
      });
    },
    upDateCur(){
      this.curAttrGroup = this.$route.query.attr_g || 0;
      this.curAttrID = this.$route.query.attr || 0;


      this.$nextTick(function (){
        if( this.$route.query.tag_id ){
          this.curCate = this.$route.query.tag_id.split(',');
          console.log( this.curCate.indexOf('4'), '标签组啊' );
        }else{
          this.curCate = [];
        }
      });

      // console.log( this.curAttrID, this.curAttrGroup, this.curCate, this.cate.filter(item=>item.id==this.curCate) );
/*      var _arr = this.cate.filter(item=>item.id==this.curCate);

      // this.subCateSelect(_arr[0].id,  _arr);
      // console.log( _arr[0].id,  _arr, 'ttt' );

      if(_arr.length > 0){
        this.subCate = _arr[0].children;
      }*/


    },
    getTag(){
      let that = this;

      if(that.$route.query.cate){
        that.category = that.$route.query.cate;
      }else{
        that.category = 0;
      }

      axios.get(this.apiPrefix + 'api/label', {
        params: {
          'uid': this.uid,
          cate: this.category
        }
      })
          .then(function (response) {
            that.cate = response.data.data
            that.upDateCur();
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  mounted() {
    let that = this;
    // that.$nextTick(function (){

    // });
    //请求数据
    that.getTag();
    window.eventBus.$on('refreshTag', function () {
      that.getTag();
    });

  },
}
</script>

<style type="less" scoped>
.group{
  text-align: left;
  padding: 0 8px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.group .van-button{
    margin-right: 5px;
    margin-bottom: 5px;
}
.search-hidden{
  max-height: 178px;
  overflow: hidden;
  margin-bottom: 10px;
}
.more-cat{
  background: #121212;
  margin: 0 8px;
}
.h2{
  padding: 40px 20px 20px 20px;
  color: #e8c507 !important;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-top: 8px;
}
</style>
