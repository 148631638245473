import Vue from 'vue'
import App from './App.vue'
import router from './router'


Vue.config.productionTip = false
//常用配置
// Vue.prototype.apiPrefix = 'http://192.168.31.27:8017/';
// Vue.prototype.apiPrefix = 'http://127.0.0.1:8026/';
Vue.prototype.apiPrefix = 'https://app-api.bigouvip.com/';
// Vue.prototype.apiPrefix = 'http://127.0.0.1:8001/';

window.eventBus = new Vue();//注册一个全局事件对象

//动态设置页面标题
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    // console.log(to.meta.title)
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

