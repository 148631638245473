<template>
  <div class="product">
    <OnlineDP />
    <div class="product-wrapper">

      <van-list
          class="feed-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad(false)"
      >

        <ProductItem :theme_color="theme_color" :theme_color_txt="theme_color_txt" :theme_color_bg="theme_color_bg" :title="item.title" :item="item" :video="item.video_url" :info="item.keywords" :series="item.series" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />

      </van-list>

      <!--<ProductItem :theme_color="theme_color" :theme_color_txt="theme_color_txt" :theme_color_bg="theme_color_bg" :series="item.series" :item="item" :video="item.video_url" :title="item.title" :info="item.keywords" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />-->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ProductItem from "@/components/ProductItem";
import OnlineDP from "@/components/OnlineDP";
import { Lazyload } from 'vant';
import { Icon } from 'vant';

Vue.use(Icon);

Vue.use(Lazyload);

import { Cell, CellGroup } from 'vant';
import axios from "axios";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'MoreRec',
  components: {
    ProductItem,
    OnlineDP
    // vueWaterfallEasy
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
      list:[],
      // imgsArr: [],
      group: 0,// request param
    };
  },
  mounted() {
    let that = this;
    console.log(that.$route.query,'路由参数');
    // that.getList(that.$route.query);

  },
  created() {
    let that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });
  },
  beforeCreate() {


    let that = this;
    window.eventBus.$on('refreshPics', function () {
      let _obj = that.$route.query;
      // if(data.key){
      //   _obj.keyword = data.key;
      // }
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.list = [];
      // console.log(_obj);
      that.queryObj = _obj;
      that.onLoad(that.queryObj);
    });



  },
  methods:{
    onLoad(params){
      let that = this;

      if( !params ){
        params = that.$route.query;
      }

      axios.get(this.apiPrefix + 'api/pics', {
        params: params
      })
          .then(function (response) {
            // that.list = response.data.data.data;

            that.loading = false;
            // that.list.concat(response.data.data.data);
            that.list.push(...response.data.data.data);
            if( response.data.data.last_page === response.data.data.current_page || response.data.data.last_page === 0 ) {
              that.finished = true;
            }else{
              that.page+=1;
              that.finished = false;
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    // getData() {
    //   axios.get('./static/mock/data.json?group=' + this.group)
    //       .then(res => {
    //         this.imgsArr = this.imgsArr.concat(res.data)
    //         this.group++
    //       })
    // },
  }
}
</script>

<style type="less">
.van-list__finished-text,.van-list__loading{
  //position: absolute;
  //left: 0;
  //right: 0;
  //width: 100%;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}
</style>
<style type="less" scoped>
.product-wrapper{
  column-count: 1;
  column-gap: 0;
  padding: 0;
}

.feed-list{
  display: flex;
  flex-wrap: wrap;
  //column-count: 2;
  //column-gap: 13px;
  padding: 0 8px;
}
.product-item{
  box-sizing: border-box;
  width: 50%;
}
.product-item:nth-child(2n-1){
  border-right: 4px #fff solid;
}
.product-item:nth-child(2n){
  border-left: 4px #fff solid;
}

</style>
