<template>
  <div>
<!--    :class="isShowMore?'':'search-hidden'"-->
    <div class="search "  style="padding-top: 0px;">
<!--      <div style="text-align: center; background: #3e9184; margin-bottom: 10px;"  @click="gotoImgSearch">-->
<!--          <van-icon name="photo-o" size="2em" style=" position: absolute; top: 7px; left: 23px; z-index: 888;" @click="gotoImgSearch" />-->

<!--        <van-row gutter="5">-->
<!--          <van-col span="20"><van-field style=" padding-left: 50px;"-->
<!--              v-model="keyword"-->
<!--              center-->
<!--              clearable-->
<!--              placeholder="请输入关键字"-->
<!--          >-->
<!--          </van-field></van-col>-->
<!--          <van-col span="4"><van-button square block type="default" color="#FF9900" @click="gotoSearch">搜索</van-button></van-col>-->
<!--        </van-row>-->
<!--        <img src="https://qn-upload.lanyanaishe.com/img/top_pic.jpg" style="max-width: 100%; max-height: 100px;">-->
<!--      </div>-->
      <div style="padding-bottom: 5px; margin: 0 8px;">
        <van-row gutter="5">
          <van-col span="14"><van-field style=" padding-left: 20px; background: #ededed;"
                                        v-model="keyword"
                                        center
                                        clearable
                                        placeholder="请输入关键字"
          >
          </van-field></van-col>
          <van-col span="4"><van-button icon="search" square block type="default" color="#121212" @click="gotoSearch"></van-button></van-col>
          <van-col span="6"><van-button square block type="default" :color="theme_color?theme_color:'#fdd600'" @click="gotoImgSearch"><span :style="{color: theme_color_txt}">以图搜图</span></van-button></van-col>
        </van-row>
      </div>

      <div v-if="isHome === false && curCate > 0">
        <div class="group">
          <van-button square type="default" size="small" color="#999999"><span :style="{color: theme_color_txt}">分类</span></van-button>
<!--          <van-button square type="default" size="small" :color="theme_color?theme_color:'#fdd600'"><span :style="{color: theme_color_txt}">分类</span></van-button>-->
<!--          <van-button square type="default" size="small" color="#999999" @click="cateSelect(0)">不选</van-button>-->
          <van-button square type="default" size="small" :color="parseInt(curCate)===item.id?(theme_color?theme_color:'#fdd600'):''" v-for="item in cate" v-bind:key="item.id" @click="cateSelect(item.id,item)"><span :style="{color: parseInt(curCate)===item.id?theme_color_txt:''}">{{item.title}}</span></van-button>
        </div>
        <div class="group" style="padding-top: 0px; display: none; ">
          <van-button square type="default" size="small" :color="parseInt(curSubCate)===item.id?(theme_color?theme_color:'#fdd600'):''" v-for="item in subCate" v-bind:key="item.id" @click="subCateSelect(item.id)">{{item.title}}</van-button>
        </div>
        <div class="group" v-for="item in attr" v-bind:key="item.id" style="display: none;">
          <van-button square type="default" size="small" :color="theme_color?theme_color:'#fdd600'" @click="attrSelect(item.id, 0)">{{item.title}}</van-button>
          <van-button square type="default" size="small" color="#999999" @click="attrSelect(item.id, 0)">不选</van-button>
          <!--         parseInt(curAttrGroup)===item.id && parseInt(curAttrID)===item2.id-->
          <van-button square type="default" size="small" :color="parseInt(curQuery['attr2_'+item.id])===item2.id?(theme_color?theme_color:'#fdd600'):''" v-for="item2 in item.children" v-bind:key="item2.id" @click="attrSelect(item.id, item2.id)">{{item2.title}}</van-button>
        </div>
      </div>

    </div>

    <div style="text-align: center; margin-bottom: 20px; display: none;">
      <van-button square type="default" size="small" color="#999999" @click="showMore"><van-icon class="icon" name="arrow-down" /> {{isShowMore?'收起':'查看更多选项'}} </van-button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import { Button } from 'vant';
import { Field } from 'vant';
import { Col, Row } from 'vant';
// import axios from "axios";

Vue.use(Col);
Vue.use(Row);

Vue.use(Field);

Vue.use(Button);

export default {
  name: 'Search',
  props: {
    // msg: String
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
      keyword:'',
      cate:[],
      attr:[],
      curCate:0,
      curTagID:0,
      curSubCate:0,
      curAttrGroup:0,
      curAttrID:0,
      isShowMore:false,
      curQuery:{},
      subCate:[]
    };
  },
  watch:{
    $route(to,from){
      // console.log(from.query['attr_11'],'qu');
      this.curQuery = to.query;
      // console.log(this.curQuery);
      console.log(from);
    }
  },
  created() {
    let that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });
  },
  methods:{
    showMore(){
      console.log('更多');
      this.isShowMore = (!this.isShowMore);
    },
    gotoSearch(){


      window.eventBus.$emit('searchKeyWord', {
        'key':this.keyword
      });

      console.log( this.$route.path === '/product' );
      if(this.$route.path !== '/product'){
        this.$router.push( { path:'/product',
          query:{
            uid: this.uid,
            keyword:this.keyword
          } } )
      }else{
        this.$router.push( { path:'/product',
          query:{
            uid: this.uid,
            keyword:this.keyword
          } } )
      }


    },
    gotoImgSearch(){

      this.$router.push( { path:'/img-search',
        query:{
          uid: this.uid
        }  } )


    },
    cateSelect(id,item){
      console.log(id,this.$router.currentRoute.path==='/product', item, 'tttt');
      if(item && item.children){
        this.subCate = item.children;
      }else{
        this.subCate = [];
      }
      console.log('子级', this.subCate);
      // if(this.$router.currentRoute.path==='/product') {
      //   //刷新数据
      //   this.$router.push( { path:'/product',
      //     query:{
      //       cate: id,
      //       uid: this.uid
      //     } } );
      // }else{
      //   this.$router.push( { path:'/product',
      //     query:{
      //       cate: id,
      //       uid: this.uid
      //     } } );
      // }

      // let _attrKey = 'attr_' + this.curAttrGroup;
      let _obj = {
        ...this.$route.query,
        cate: id,
        sub_cate: 0,
        uid: this.uid,
        attr_g: this.curAttrGroup,
        attr: this.curAttrID,
        tag_id: 0,
        // _attrKey: this.curAttrID,
      };
      _obj['attr2_' + this.curAttrGroup] = this.curAttrID;

      console.log(_obj);

      this.$router.push( { path:'/product',
        query: _obj} );

      this.curCate = id;
      this.curSubCate = 0;
      window.eventBus.$emit('refreshPics', {
      });
      window.eventBus.$emit('refreshTag', {
      });
    },
    subCateSelect(id){
      let _obj = {
        ...this.$route.query,
        cate: this.curCate,
        sub_cate: id,
        uid: this.uid,
        attr_g: this.curAttrGroup,
        attr: this.curAttrID,
        tag_id: 0,
        // _attrKey: this.curAttrID,
      };
      _obj['attr2_' + this.curAttrGroup] = this.curAttrID;

      console.log(_obj);

      this.$router.push( { path:'/product',
        query: _obj} );

      this.curSubCate = id;
      window.eventBus.$emit('refreshPics', {
      });
    },
    attrSelect(group, id){
      console.log(group, id);

      let _obj = {
        ...this.$route.query,
        attr_g: group,
        attr: id,
        uid: this.uid,
        cate: this.curCate,
      };

      _obj['attr2_' + group] = id;

      this.$router.push( { path:'/product',query: _obj} );
      this.curAttrGroup = group;
      this.curAttrID = id;

      window.eventBus.$emit('refreshPics', {
      });
    },
    upDateCur(){
      this.curAttrGroup = this.$route.query.attr_g || 0;
      this.curAttrID = this.$route.query.attr || 0;
      this.curCate = this.$route.query.cate || 0;
      console.log( this.curAttrID, this.curAttrGroup, this.curCate, this.cate.filter(item=>item.id==this.curCate) );
      var _arr = this.cate.filter(item=>item.id==this.curCate);

      // this.subCateSelect(_arr[0].id,  _arr);
      // console.log( _arr[0].id,  _arr, 'ttt' );

      if(_arr.length > 0){
        this.subCate = _arr[0].children;
      }


    }
  },
  beforeCreate() {


    this.$nextTick(function (){
      // if( this.$route.query.tag_id && this.$route.query.tag_id > 0 ){
      //   this.curTagID = this.$route.query.tag_id;
        this.curCate = this.$route.query.cate;
      var _arr = [];
        if( this.$route.query.tag_id ){
          _arr = this.$route.query.tag_id.split(',');
        }else{
          _arr = [];
        }

      if(_arr.length > 0 && _arr[0] > 0 ) {
        this.curTagID = 1;
        // console.log(this.$route.query.tag_id, '标签ID99');
      }else{
        this.curTagID = 0;
        // console.log(this.$route.query.tag_id, '标签ID9999');
      }
    });

    // console.log( this.curAttrID, this.curAttrGroup, this.curCate );

    //请求数据
    // let that = this;
    // axios.get(this.apiPrefix + 'api/cate', {
    //   params: {
    //   }
    // })
    //     .then(function (response) {
    //       that.cate = response.data.data
    //       that.upDateCur();
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });

/*    axios.get(this.apiPrefix + 'api/cate', {
      params: {
        'uid': that.uid
      }
    })
        .then(function (response) {
          that.cate = response.data.data
          that.upDateCur();
        })
        .catch(function (error) {
          console.log(error);
        });*/

    // axios.get(this.apiPrefix + 'api/attr', {
    //   params: {
    //   }
    // })
    //     .then(function (response) {
    //       that.attr = response.data.data
    //       console.log(that.attr);
    //       that.upDateCur();
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });

  }
}
</script>

<style type="less" scoped>
.group{
  text-align: left;
  padding: 0 8px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.group .van-button{
    margin-right: 5px;
    margin-bottom: 5px;
}
.search-hidden{
  max-height: 178px;
  overflow: hidden;
  margin-bottom: 10px;
}
.more-cat{
  background: #121212;
  margin: 0 8px;
}
.h2{
  padding: 40px 20px 20px 20px;
  color: #e8c507 !important;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-top: 8px;
}
</style>
