<template>
  <div id="app">
    <router-view/>
    <BottomNav />
  </div>
</template>

<style>

body{
  background: #fff;
}

@media screen and (min-width: 640px){
  body{
    /*background: #ebebeb;*/
  }
  #app{
    position: relative;
    /*width: 640px;*/
    margin: 0 auto;
    background: #fff;
  }
  .product-item{
    border: 3px solid #fff !important;
    margin: 0 !important;
    box-sizing: content-box;
  }
  .banben{
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }
  .banben-item{
    width: 50%;
    box-sizing: border-box;
  }
  .content-warp,.about{
    width: 640px;
    margin: 0 auto;
  }
  .case-wrapper{
    width: 640px;
    margin: 0 auto;
    /*display: flex !important;*/
    /*flex-wrap: wrap !important;*/
  }
  .case-wrapper .product-item{
    width: 100% !important;
  }
}
@media screen and (min-width: 640px) and (max-width: 1000px) {
  #app{
  }
  .product-item{
    width: 30% !important;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  #app{
  }
  .product-item{
    width: 25% !important;
  }
  .banben-item{
    width: 33.33%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1440px) {
  #app{
  }
  .product-item{
    width: 20% !important;
  }
  .banben-item{
    width: 25%;
  }
}
@media screen and (min-width: 1441px) {
  #app{
  }
  .product-item{
    width: 16.66% !important;
  }
  .banben-item{
    width: 20%;
  }
}

.product-wrapper{
  /*display: flex;*/
  /*display: -webkit-flex;*/
  /*justify-content: space-between;*/
  /*flex-direction: row;*/
  /*flex-wrap: wrap;*/
  /*margin-left: 13px;*/
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));*/
  /*grid-gap: 13px;*/
  /*grid-auto-flow: row dense;*/
  /*grid-template-rows: minmax(100px, auto);*/
  /*grid-auto-flow: dense;*/
  /*column-count: 2;*/
  /*grid-gap: 0.25em;*/
  /*grid-auto-flow: row dense;*/
  /*grid-auto-rows: 13px;*/
  column-count: 2;
  column-gap: 13px;
  padding: 0 8px;

}
.product-wrapper .product-item{
  width: 100%;
  margin-bottom: 13px;
  /*grid-row: auto / span 5;*/
  /*grid-row: auto / auto;*/
  /*grid-template-rows: 1fr auto;*/
  /*width: calc(50% - 13px);*/
  /*margin: 15px 13px 0 0 ;*/
  break-inside: avoid;
}
.m-13{
  margin: 13px;
}
.contact-me{
  margin-bottom: 80px;
}
.text-align-left{
  text-align: left;
}
.text-align-center{
  text-align: center;
}
.contact-me-title{
  padding: 10px;
  border-bottom: 1px solid #E6E6E6;
}
.font-weight-bold{
  font-weight: bold;
}
.invalid-acc{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 9999999;
  text-align: center;
}
</style>
<script>
import BottomNav from "@/components/BottomNav";
import Vue from 'vue'
import axios from "axios";
import wx from "weixin-js-sdk";

export default {
  components: {BottomNav},
  created() {
    Vue.prototype.uid = this.$route.query.uid || 0;
    Vue.prototype.userInfo = '';
    Vue.prototype.themeColor = '';
    Vue.prototype.themeColorTxt = '';
    Vue.prototype.themeColorBg = '';
    Vue.prototype.themeColorBgTxt = '';
    Vue.prototype.userImg = '';
    // Vue.prototype.userInfo = false;
    // Vue.prototype.themeColor = false;
    // Vue.prototype.themeColorTxt = false;
    // Vue.prototype.themeColorBg = false;
    // Vue.prototype.userImg = false;
    console.log( this.$route.query.uid , this.uid);

    //请求用户数据
    axios.get(this.apiPrefix + 'api/user/' + this.uid + '?url=' + encodeURIComponent(window.location.href.split("#")[0]), {
      params: {
      }
    })
        .then(function (response) {

          if( !response.data.data.status || response.data.data.status!==1){
            var div = document.createElement('div');
            div.innerHTML = '该账户已停用';
            div.className = "invalid-acc";
            var bo = document.body;
            bo.insertBefore(div, bo.lastChild);
            alert('该账号已停用');
          }

          console.log(response.data.data.info, '账号信息');

          wx.config(
              response.data.data.wxConfig
          );

          wx.ready(() => {
            var shareData = {
              // title: response.data.data.info.shop_name + '背景图库，电话：' + response.data.data.info.telphone,
              title: response.data.data.info.shop_name + '艺术背景定制',
              desc: "精品艺术背景定制，月月有新品，敬请关注。",
              link: response.data.data.wxConfig.url,
              imgUrl:
                  "https://qn-upload.lanyanaishe.com/20230329/15bc6771974a4fbf0988555cd8341e11.png?imageView2/0/w/120/format/jpg",
              success: () => { },
            };
            wx.updateAppMessageShareData(shareData);
            wx.updateTimelineShareData(shareData);
          });

          Vue.prototype.userInfo = response.data.data.info;
          Vue.prototype.themeColor = response.data.data.color?response.data.data.color.color:'';
          Vue.prototype.themeColorTxt = response.data.data.color?response.data.data.color.color_txt:'';
          Vue.prototype.themeColorBg = response.data.data.color?response.data.data.color.color_bg:'';
          Vue.prototype.themeColorBgTxt = response.data.data.color?response.data.data.color.color_bg_txt:'';
          Vue.prototype.userImg = response.data.data.img;
          window.eventBus.$emit('userInfo', {});
          window.eventBus.$emit('refreshThemeColor', {});
        })
        .catch(function (error) {
          console.log(error);
        });




  }
}
</script>
