<template>
  <div class="product">

    <div style="text-align: center; margin-bottom: 20px; padding: 10px; margin-top:20px;">
<!--      <h3>拍照识图</h3>-->
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1">
        <van-button icon="plus" type="primary">请上传图片文件</van-button>
      </van-uploader>
    </div>



    <div class="product-wrapper">

      <van-list
          class="feed-list"
          finished-text="没有更多了"
      >

      <ProductItem :series="item.series" :item="item" :video="item.video_url" :title="item.title" :info="item.keywords" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />

      </van-list>

<!--      <vue-waterfall-easy :imgsArr="list" @scrollReachBottom="getList">-->
<!--        <ProductItem slot-scope="props" :title="props.value.title" :size="props.value.size" :cate="props.value.category" :img="props.value.img_url" v-bind:key="props.value.id" />-->
<!--      </vue-waterfall-easy>-->



    </div>
    <div style="text-align: center; font-size: 12px; color: #ccc; ">{{tip}}</div>

    <OnlineDP />


  </div>
</template>

<script>
import Vue from 'vue';
import ProductItem from "@/components/ProductItem";
import { Uploader } from 'vant';
// import vueWaterfallEasy from 'vue-waterfall-easy'
import { Lazyload } from 'vant';
import { Icon } from 'vant';

import { List } from 'vant';

Vue.use(Icon);
Vue.use(List);

Vue.use(Lazyload);
Vue.use(Uploader);

// console.log('以图·搜图');

import { Cell, CellGroup } from 'vant';
import axios from "axios";
import OnlineDP from "@/components/OnlineDP";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'ImgSearch',
  components: {
    ProductItem,
    OnlineDP
    // vueWaterfallEasy
  },
  data() {
    return {
      list:[],
      fileList:[],
      tip:'',
      // imgsArr: [],
      group: 0,// request param
    };
  },
  mounted() {
    // let that = this;
    // that.getList({});
  },
  beforeCreate() {


    let that = this;
    window.eventBus.$on('refreshPics', function () {
      console.log(that.$route.query);
      // that.getList(that.$route.query);
    });


  },
  methods:{
    // beforeRead(file) {
    //   // console.log(file, this.fileList);
    //   // if (file.type !== 'image/jpeg') {
    //   //   Toast('请上传 jpg 格式图片');
    //   //   return false;
    //   // }
    //   return true;
    // },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      //未压缩图片，可能很大
      // console.log(file.content.replace(/^data:image\/\w+;base64,/, ''), this.fileList);
      this.tip = '';
      this.compress(file.content);
    },

    getList(sendData={}){
      let that = this;
      axios.post(this.apiPrefix + 'api/img_search', sendData)
          .then(function (response) {

            if(response.data.data && response.data.data[0].result){
              console.log(response.data.data[0].result);
              that.list = response.data.data[0].result;
            }else{
              that.list = [];
              that.tip = '无相关数据';
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    // getData() {
    //   axios.get('./static/mock/data.json?group=' + this.group)
    //       .then(res => {
    //         this.imgsArr = this.imgsArr.concat(res.data)
    //         this.group++
    //       })
    // },
    /**
     * 压缩图片
     * @param res
     */
    compress(res) {

      var that = this;
      var img = new Image(),
          //最大500
          maxH = 500;

      img.onload = function () {
        var cvs = document.createElement('canvas'),
            ctx = cvs.getContext('2d');

        if (img.height > maxH) {
          img.width *= maxH / img.height;
          img.height = maxH;
        }
        cvs.width = img.width;
        cvs.height = img.height;

        var _h = cvs.height;
        cvs.height = 0;
        cvs.height = _h;
        ctx.clearRect(0, 0, cvs.width, cvs.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var dataUrl = cvs.toDataURL('image/jpeg', 0.8);
        var _base64 = dataUrl.replace('data:image/jpeg;base64,', '');

        //todo 传输base64数据过去，以图搜图
        that.getList({'data':_base64});

      };

      img.src = res;
    }
  }
}



</script>
<style type="less" scoped>
.product-wrapper{
  column-count: 1;
  column-gap: 0;
  padding: 0;
}

.feed-list{
  display: flex;
  flex-wrap: wrap;
  //column-count: 2;
  //column-gap: 13px;
  padding: 0 8px;
}
.product-item{
  box-sizing: border-box;
  width: 50%;
}
.product-item:nth-child(2n-1){
  border-right: 4px #fff solid;
}
.product-item:nth-child(2n){
  border-left: 4px #fff solid;
}

</style>
