import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from "@/views/About";
import Product from "@/views/Product";
import ImgSearch from "@/views/ImgSearch";
import MoreRec from "@/views/MoreRec";
import Cases from "@/views/Cases";
import Gongyi from "@/views/Gongyi";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: '首页-艺术背景定制'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    component: About,//todo 动态加载无法获取到路由参数 https://blog.csdn.net/lovexiaobaby/article/details/93618297
    meta:{
      title: '简介-艺术背景定制'
    }
  },
  {
    path: '/product',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    component: Product,
    meta:{
      title: '产品-艺术背景定制'
    }
  },
  {
    path: '/cases',
    name: 'Cases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    component: Cases,
    meta:{
      title: '案例-艺术背景定制'
    }
  },
  {
    path: '/more-rec',
    name: 'MoreRec',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    component: MoreRec,
    meta:{
      title: '艺术背景定制'
    }
  },
  {
    path: '/img-search',
    name: 'ImgSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    component: ImgSearch,
    meta:{
      title: '拍照识图-艺术背景定制'
    }
  },
  {
    path: '/gongyi',
    name: 'Gongyi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    component: Gongyi,
    meta:{
      title: '工艺-艺术背景定制'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
