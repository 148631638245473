<template>
  <div class="product" style="padding-top: 8px;">
    <OnlineDP />
    <div :style="'height:'+setHolderHeight+'px'">
    </div>
    <div ref="headerNav">
      <Search />
      <van-tabs v-model="active" :color="theme_color" @change="onChangeTab">
        <van-tab title="按风格"><Category/></van-tab>
        <van-tab title="按版本">
          <div class="banben" style="padding-bottom: 50px;">
            <div v-for="item in banbenList" :key="item.id" class="banben-item" @click="onBanbenShow(item.id)">
              <div class="banben-item-img">
                <van-image
                class="item-img2"
                width="100%"
                lazy-load
                fit="contain"
                :src="item.img_url+ '?imageView2/0/w/200/format/jpg'"
                />
              </div>
              <div class="banben-item-info">
                <div class="item-info-title">{{item.title}}</div>
                <div class="item-info-desc">{{item.desc}}</div>
                <div class="item-info-more">
                  <span v-if="item.categoryNianfen">{{item.categoryNianfen.title}}</span>
                  <span v-if="item.category">{{item.category.title}}</span>
                  <span v-if="item.categoryChanpin">{{item.categoryChanpin.title}}</span>
                  <span v-if="item.categoryGongyi">{{item.categoryGongyi.title}}</span>
                </div>

              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

<!--    <Label v-if="curTagID > 0 || curCateID > 0" />-->
    <div class="product-wrapper" v-show="active==0">

      <van-list
          class="feed-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad(false)"
      >

        <ProductItem :video="item.video_url" :theme_color="theme_color" :theme_color_txt="theme_color_txt" :theme_color_bg="theme_color_bg" :title="item.title" :item="item" :info="item.keywords" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />

      </van-list>

      <!--      <vue-waterfall-easy :imgsArr="list" @scrollReachBottom="getList">-->
      <!--        <ProductItem slot-scope="props" :title="props.value.title" :size="props.value.size" :cate="props.value.category" :img="props.value.img_url" v-bind:key="props.value.id" />-->
      <!--      </vue-waterfall-easy>-->

    </div>

    <van-popup
        v-model="show"
        closeable
        position="bottom"
        :style="{ height: '80%' }"
    >

      <div style="padding: 10px 0px;">
        <div v-if="isLoaded==false" style="text-align: center; padding: 10px; font-size: 12px; color: #ccc;">搜索相似图中，请稍候...</div>

        <div class="product-wrapper">

          <van-list
              class="feed-list"
              finished-text="没有更多了"
          >

            <ProductItem :video="item.video_url" :show-simp="false" :item="item" :title="item.title" :info="item.keywords" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list2" />

          </van-list>
        </div>
      </div>


    </van-popup>

    <van-popup
        v-model="show3"
        closeable
        position="bottom"
        :style="{ height: '80%' }"
    >

      <div style="padding: 10px 0px;">
        <div v-if="isLoaded3==false" style="text-align: center; padding: 10px; font-size: 12px; color: #ccc;">加载中，请稍候...</div>

        <div class="product-wrapper">

          <van-list
              class="feed-list"
              finished-text="没有更多了"
          >
            <!-- eslint-disable -->
            <ProductItem :video="item.video_url" :show-simp="false" :item="item" :title="item.product.title" :info="item.product.keywords" :size="item.product.size" :cate="item.product.category" :img="item.product.img_url" v-bind:key="item.id" v-for="item in list3" v-if="item.product" />
            <!-- eslint-disable -->

          </van-list>
        </div>
      </div>


    </van-popup>

  </div>
</template>

<script>
import Vue from 'vue';
import Search from "@/components/Search";
// import Label from "@/components/Label";
import ProductItem from "@/components/ProductItem";
import OnlineDP from "@/components/OnlineDP";
// import vueWaterfallEasy from 'vue-waterfall-easy'
import { Lazyload } from 'vant';
import { Icon } from 'vant';
import { List } from 'vant';
import { Tab, Tabs } from 'vant';

Vue.use(Icon);
Vue.use(List);

Vue.use(Tab);
Vue.use(Tabs);

Vue.use(Lazyload);

import { Cell, CellGroup } from 'vant';
import axios from "axios";
import Category from "@/components/Category";

// import Headroom from "headroom.js";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

// var headroom;

export default {
  name: 'Product',
  components: {
    Category,
    ProductItem,
    Search,
    // Label,
    OnlineDP
    // vueWaterfallEasy
  },
  data() {
    return {
      active:0,
      loading: false,
      finished: false,
      page: 1,
      curTagID:0,
      curCateID:0,
      list:[],
      // imgsArr: [],
      group: 0,// request param
      queryObj: {}, //查询参数
      queryObj2: {}, //查询参数
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
      holderHeight:0,
      setHolderHeight:0,
      show: false,
      isLoaded: false,
      list2: [],
      cur_id: 0,
      show3: false,
      isLoaded3: false,
      list3: [],
      banbenList: []
    };
  },
  mounted() {
    let that = this;
    console.log(that.$route.query,'路由参数');

    // var that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });

    that.queryObj = that.$route.query;


    window.eventBus.$on('searchKeyWord', function (data) {
      // console.log('搜索时',data);
      let _obj = that.$route.query;
      if(data.key){
        _obj.keyword = data.key;
      }
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.list = [];
      // console.log(_obj);
      that.queryObj = _obj;
      that.onLoad(that.queryObj);
    });

    var myElement = that.$refs.headerNav;
    setTimeout(function (){
      // console.log(window.getComputedStyle(myElement).height, '99999');
      that.holderHeight = parseInt(window.getComputedStyle(myElement).height.replace('px',''));
      console.log(that.holderHeight);
    },2000);


    // headroom  = new Headroom(myElement, {
    //   offset: 340,
    //   onPin : function() {},
    //   // callback when unpinned, `this` is headroom object
    //   onUnpin : function() {},
    //   // callback when above offset, `this` is headroom object
    //   onTop : function() {
    //     that.setHolderHeight = 0;
    //     // console.log('在顶部');
    //     // console.log(window.getComputedStyle(myElement).height, '99999');
    //   },
    //   // callback when below offset, `this` is headroom object
    //   onNotTop : function() {
    //     that.setHolderHeight = that.holderHeight;
    //   },
    //   // callback when at bottom of page, `this` is headroom object
    //   onBottom : function() {},
    //   // callback when moving away from bottom of page, `this` is headroom object
    //   onNotBottom : function() {}
    // });
    // headroom.init();


    window.eventBus.$on('switchNav', function (e) {
      console.log(e, '切换');
      if(e.tab===1){
        // headroom.freeze();
        console.log('dddd');
      }else{
        console.log('重新');
      }
    });


  },
  created() {
  },
  beforeCreate() {
    let that = this;


    this.$nextTick(function (){


      that.curCateID = this.$route.query.cate;

      if( this.$route.query.tag_id  ){

        // console.log( this.$route.query.tag_id.split(','), '拆分标签' );
        var _arr = this.$route.query.tag_id.split(',');
        if(_arr.length > 0 && _arr[0] > 0 ) {
          this.curTagID = 1;
        }else{
          this.curTagID = 0;
          // this.curTagID = this.$route.query.tag_id;
        }


        // console.log(this.$route.query.tag_id, '标签ID99');
      }else{

        this.curTagID = 0;

        // console.log(this.$route.query.tag_id, '标签ID9999');
      }
    });

    window.eventBus.$on('refreshPics', function () {
      that.queryObj = that.$route.query;
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.list = [];
      that.queryObj.keyword = '';

      that.onLoad(false);

      console.log('数据更新啊');
      // that.getList( that.queryObj );
    });


    window.eventBus.$on('loadSimplePics', function (e) {
      that.show = true;
      that.isLoaded = false;
      that.list2 = [];
      that.getList2(e);
      that.cur_id = e.cur_id;
      // console.log(e);
    });

  },
  methods:{
    getList2(sendData={}){
      let that = this;
      axios.post(this.apiPrefix + 'api/img_search', sendData)
          .then(function (response) {

            if(response.data.data && response.data.data[0].result){
              console.log(response.data.data[0].result);
              that.list2 = response.data.data[0].result;
              that.list2 = that.list2.filter((item) => {
                return item.id !== that.cur_id;
              });

            }else{
              that.list2 = [];
              that.tip = '无相关数据';
            }
            that.isLoaded = true;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getList3(id){
      let that = this;
      axios.get(this.apiPrefix + 'api/banbenList?product_id=' + id)
          .then(function (response) {
            if(response.data.data.length>0){
              that.list3 = response.data.data
            }else{
              that.list3 = [];
              // that.tip = '无相关数据';
            }
            that.isLoaded3 = true;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    onChangeTab(e){
      console.log(e);
      if( this.active === 1 ){
        this.getBanben()
      }
      window.eventBus.$emit('switchNav', {
        'tab':this.active
      });
    },
    getBanben(){
      var that = this
      // that.queryObj = that.$route.query;
      that.page = 1;
      that.limit = 200;
      // that.loading = false;
      // that.finished = true;
      // that.list = [];
      // that.queryObj.keyword = '';
      axios.get(that.apiPrefix + 'api/banben', {
        params: that.queryObj2
      })
          .then(function (response) {
            console.log(response);
            that.banbenList = response.data.data
          })
    },
    // getList(params={}){
    //   let that = this;
    //   axios.get(this.apiPrefix + 'api/pics', {
    //     params: params
    //   })
    //       .then(function (response) {
    //         that.list = response.data.data.data;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // },
    onLoad(params) {

      //当是版本的时候，不需要加载
      // console.log('ddddd', this.active===1);
      // if(this.active===1) return;

      var that = this;

      if(!params){
        var _obj = that.$route.query;
        _obj.page = that.page;
        that.queryObj = _obj;
      }

      console.log(that.queryObj, '参数啊');

      axios.get(that.apiPrefix + 'api/pics', {
        params: that.queryObj
      })
          .then(function (response) {
            that.loading = false;
            // that.list.concat(response.data.data.data);
            that.list.push(...response.data.data.data);
            if( response.data.data.last_page === response.data.data.current_page || response.data.data.last_page === 0 ) {
              that.finished = true;
            }else{
              that.page+=1;
              that.finished = false;
            }

            console.log(that.theme_color, '皮肤颜色');

          })
          .catch(function (error) {
            console.log(error);
          });

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //
      //   // 加载状态结束
      //   this.loading = false;
      //
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    onBanbenShow(id){
      let that = this;
      that.show3 = true;
      that.isLoaded3 = false;
      that.list3 = [];
      that.getList3(id);
    },
    // getData() {
    //   axios.get('./static/mock/data.json?group=' + this.group)
    //       .then(res => {
    //         this.imgsArr = this.imgsArr.concat(res.data)
    //         this.group++
    //       })
    // },
  }
}
</script>
<style type="less">
.van-list__finished-text,.van-list__loading{
  //position: absolute;
  //left: 0;
  //right: 0;
  //width: 100%;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}
</style>
<style type="less" scoped>
.product-wrapper{
  column-count: 1;
  column-gap: 0;
  padding: 0;
}

.feed-list{
  display: flex;
  flex-wrap: wrap;
  //column-count: 2;
  //column-gap: 13px;
  padding: 0 8px;
}
.product-item{
  box-sizing: border-box;
  width: 50%;
}
.product-item:nth-child(2n-1){
  border-right: 4px #fff solid;
}
.product-item:nth-child(2n){
  border-left: 4px #fff solid;
}

</style>
<style type="less">
.headroom--pinned {
  background: #fff;
  position: fixed;
  z-index: 999;
  padding-top: 8px;
  top: 0;
  right: 0;
  left: 0;
  display: block;
}
.headroom--unpinned {
  display: none;
}
.headroom--top{
  position: static;
  padding-top: 0;
}

/*.headroom {
  will-change: transform;
  transition: transform 600ms;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}*/

.fancybox__container{
  z-index: 9999;
}

.banben-item{
  display: flex;
  padding: 10px 10px 0 10px;
}
.banben-item-img{
  flex: 2;
}
.banben-item-info{
  flex: 4;
  padding-left: 10px;
}
.item-img2{
  margin: 0 !important;
}
.item-info-title{
  font-weight: bolder;
}
.item-info-desc{
  border-bottom: solid 1px #ccc;
  padding: 5px 0;
  margin-bottom: 5px;
  font-size: 13px;
}
.item-info-more span{
  font-size: 12px;
  color: #ccc;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

</style>
