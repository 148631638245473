<template>
  <div class="about">
    <div class=" m-13">
      <van-cell-group>
        <van-row gutter="5" class="contact-me-title">
          <van-col span="20" class="text-align-left font-weight-bold"><van-icon name="description" style="font-size: 20px; vertical-align: middle;" /> 我的简介</van-col>
        </van-row>
        <van-cell :title="uInfo.intro" class="text-align-left" />
      </van-cell-group>
    </div>

    <div class=" m-13" style="display:none;">
      <van-cell-group>
        <van-row gutter="5" class="contact-me-title">
          <van-col span="20" class="text-align-left font-weight-bold"><van-icon name="description" style="font-size: 20px; vertical-align: middle;" /> 我提供的服务</van-col>
        </van-row>
        <van-cell :title="uInfo.service" class="text-align-left" />
      </van-cell-group>
    </div>

    <div class=" m-13" style="display: none;">
      <van-cell-group>
        <van-row gutter="5" class="contact-me-title">
          <van-col span="20" class="text-align-left font-weight-bold"><van-icon name="description" style="font-size: 20px; vertical-align: middle;" /> 店面环境</van-col>
        </van-row>
        <van-cell title="暂无" v-if="!list" />
        <a data-fancybox="gallery" :href="item.img_url" :data-caption="item.title"  v-for="item in list" :key="item.id">
          <van-image
              width="30%"
              height="100"
              lazy-load
              fit="cover"
              :src="item.img_url+ '?imageView2/0/w/400/format/jpg'"
          />
        </a>
      </van-cell-group>
    </div>

    <div class="contact-me m-13">
      <van-cell-group>
        <van-row gutter="5" class="contact-me-title">
          <van-col span="20" class="text-align-left font-weight-bold"><van-icon name="description" style="font-size: 20px; vertical-align: middle;" /> 联系方式</van-col>
        </van-row>
        <van-cell title="店名" class="text-align-left" :value="uInfo.shop_name" />
        <van-cell title="负责人" class="text-align-left" :value="uInfo.fuzeren" />
        <van-cell title="电话" class="text-align-left" :value="uInfo.telphone" />
        <van-cell title="微信" class="text-align-left" :value="uInfo.wechat" />
        <van-cell title="店面地址" class="text-align-left" :value="uInfo.address" />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Button,Image as VanImage } from 'vant';

Vue.use(Button);
Vue.use(VanImage);

export default {
  name: 'About',
  // components: {
  // }
  data() {
    return {
      uInfo:{},
      list:[],
    };
  },
beforeCreate() {

  let that = this;
  window.eventBus.$on('userInfo', function () {
    that.uInfo = that.userInfo;
    that.list = that.userImg;
  });

},
  created() {
    /* eslint-disable*/
    // console.log($)

    // console.log( this.$route.query.uid );
    if(this.userInfo){
      this.uInfo = this.userInfo
      this.list = this.userImg
    }


  },
}
</script>
<style>
.van-image{
  margin: 10px 0 0 10px;
}
.van-cell__title>span {
  display: block;
  white-space: pre-line;
}
</style>
