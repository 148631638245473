<template>
  <div class="product">
    <div class="product-wrapper case-wrapper">
      <CasetItem :title="item.title" :is_video="item.is_video" :thumb="item.thumb_url" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import CasetItem from "@/components/CasetItem";
// import vueWaterfallEasy from 'vue-waterfall-easy'
import { Lazyload } from 'vant';
import { Icon } from 'vant';

Vue.use(Icon);

Vue.use(Lazyload);

import { Cell, CellGroup } from 'vant';
import axios from "axios";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'Cases',
  components: {
    CasetItem,
    // vueWaterfallEasy
  },
  data() {
    return {
      list:[],
      // imgsArr: [],
      group: 0,// request param
    };
  },
  mounted() {
    let that = this;
    console.log(that.$route.query,'路由参数');
    that.getList(that.$route.query);
  },
  beforeCreate() {


    let that = this;
    window.eventBus.$on('refreshPics', function () {
      that.getList(that.$route.query);
    });



  },
  methods:{
    getList(params={}){
      let that = this;
      axios.get(this.apiPrefix + 'api/cases', {
        params: params
      })
          .then(function (response) {
            that.list = response.data.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>
