<template>
  <div class="product">
    <OnlineDP />
    <Search v-if="curCateID > 0" />
    <Label v-if="curTagID > 0 || curCateID > 0" />
    <div class="product-wrapper">

      <van-list
          class="feed-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad(false)"
      >

      <ProductItem :theme_color="theme_color" :theme_color_txt="theme_color_txt" :theme_color_bg="theme_color_bg" :title="item.title" :item="item" :video="item.video_url" :info="item.keywords" :series="item.series" :size="item.size" :cate="item.category" :img="item.img_url" v-bind:key="item.id" v-for="item in list" />

      </van-list>

<!--      <vue-waterfall-easy :imgsArr="list" @scrollReachBottom="getList">-->
<!--        <ProductItem slot-scope="props" :title="props.value.title" :size="props.value.size" :cate="props.value.category" :img="props.value.img_url" v-bind:key="props.value.id" />-->
<!--      </vue-waterfall-easy>-->

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Search from "@/components/Search";
import Label from "@/components/Label";
import ProductItem from "@/components/ProductItem";
import OnlineDP from "@/components/OnlineDP";
// import vueWaterfallEasy from 'vue-waterfall-easy'
import { Lazyload } from 'vant';
import { Icon } from 'vant';
import { List } from 'vant';

Vue.use(Icon);
Vue.use(List);

Vue.use(Lazyload);

import { Cell, CellGroup } from 'vant';
import axios from "axios";

// import $ from 'jquery'

Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'Product',
  components: {
    ProductItem,
    Search,
    Label,
    OnlineDP
    // vueWaterfallEasy
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      curTagID:0,
      curCateID:0,
      list:[],
      // imgsArr: [],
      group: 0,// request param
      queryObj: {}, //查询参数
      theme_color_bg: '',
      theme_color: '',
      theme_color_txt: '',
    };
  },
  mounted() {
    let that = this;
    console.log(that.$route.query,'路由参数');

    // var that = this;
    that.theme_color = that.themeColor;
    that.theme_color_txt = that.themeColorTxt;
    that.theme_color_bg = that.themeColorBg;
    window.eventBus.$on('refreshThemeColor', function () {
      that.theme_color = that.themeColor;
      that.theme_color_txt = that.themeColorTxt;
      that.theme_color_bg = that.themeColorBg;
    });

    that.queryObj = that.$route.query;

    //todo 之前的 that.getList(that.queryObj);

    window.eventBus.$on('searchKeyWord', function (data) {
      // console.log('搜索时',data);
      let _obj = that.$route.query;
      if(data.key){
        _obj.keyword = data.key;
      }
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.list = [];
      // console.log(_obj);
      that.queryObj = _obj;
      that.onLoad(that.queryObj);
    });

  },
  beforeCreate() {
    let that = this;


    this.$nextTick(function (){


      that.curCateID = this.$route.query.cate;

      if( this.$route.query.tag_id  ){

        // console.log( this.$route.query.tag_id.split(','), '拆分标签' );
        var _arr = this.$route.query.tag_id.split(',');
        if(_arr.length > 0 && _arr[0] > 0 ) {
          this.curTagID = 1;
        }else{
          this.curTagID = 0;
          // this.curTagID = this.$route.query.tag_id;
        }


        // console.log(this.$route.query.tag_id, '标签ID99');
      }else{

        this.curTagID = 0;

        // console.log(this.$route.query.tag_id, '标签ID9999');
      }
    });

    window.eventBus.$on('refreshPics', function () {
      that.queryObj = that.$route.query;
      that.page = 1;
      that.loading = false;
      that.finished = true;
      that.list = [];
      that.queryObj.keyword = '';

      that.onLoad(false);

      console.log('数据更新啊');
      // that.getList( that.queryObj );
    });



  },
  methods:{
    // getList(params={}){
    //   let that = this;
    //   axios.get(this.apiPrefix + 'api/pics', {
    //     params: params
    //   })
    //       .then(function (response) {
    //         that.list = response.data.data.data;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // },
    onLoad(params) {

      var that = this;

      if(!params){
        var _obj = that.$route.query;
        _obj.page = that.page;
        that.queryObj = _obj;
      }

      console.log(that.queryObj, '参数啊');

      axios.get(that.apiPrefix + 'api/pics', {
        params: that.queryObj
      })
          .then(function (response) {
            that.loading = false;
            // that.list.concat(response.data.data.data);
            that.list.push(...response.data.data.data);
            if( response.data.data.last_page === response.data.data.current_page || response.data.data.last_page === 0 ) {
              that.finished = true;
            }else{
              that.page+=1;
              that.finished = false;
            }

            console.log(that.theme_color, '皮肤颜色');

          })
          .catch(function (error) {
            console.log(error);
          });

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //
      //   // 加载状态结束
      //   this.loading = false;
      //
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    }
    // getData() {
    //   axios.get('./static/mock/data.json?group=' + this.group)
    //       .then(res => {
    //         this.imgsArr = this.imgsArr.concat(res.data)
    //         this.group++
    //       })
    // },
  }
}
</script>
<style type="less">
.van-list__finished-text,.van-list__loading{
  //position: absolute;
  //left: 0;
  //right: 0;
  //width: 100%;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}
</style>
<style type="less" scoped>
.product-wrapper{
  column-count: 1;
  column-gap: 0;
  padding: 0;
}

.feed-list{
  display: flex;
  flex-wrap: wrap;
  //column-count: 2;
  //column-gap: 13px;
  padding: 0 8px;
}
.product-item{
  box-sizing: border-box;
  width: 50%;
}
.product-item:nth-child(2n-1){
  border-right: 4px #fff solid;
}
.product-item:nth-child(2n){
  border-left: 4px #fff solid;
}

</style>
